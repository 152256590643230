.report-crd-cont {
    position: relative;
    background-color: rgba(0, 126, 255, 1);
    border-radius: 4px;
    
}

.report-crd-cont img{
    width: 100%;
    height: 100%;
}
// .report-crd-cont::before {
    
// }
// .report-crd-cont::before {
    
//     content: "";
//     position: absolute;
//     top: 26px;
//     right: 35px;
//     transform: rotate(137deg);
//     transform-origin: top right;
//     width: 1cm;
//     height: 0.3cm;
//     background-color: white;
//     border-radius: 20px;
// }

.report-crd-cont-line{
    border-top: 1px solid #FFFFFF;
    width: 75%;
    margin-top: 5px;
    
}
.report-crd-date{
   
    letter-spacing: 0.3px;
    margin-top: 5px;
    color: rgba(255, 255, 255, 1);
 
}