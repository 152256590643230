.data_stats p{
    font-weight: 500;
    margin-bottom: 12px;
}
.data_stats ul{
    padding: 0em 1em;
}
.data_stats ul li{
    color: #4B4F57;
    margin-bottom: 0.5em;
}   
.data_columns{
    padding: 1.25em;
    border-radius: 12px;
    border:1px solid #DDD8D0;
    margin: 2em 0em;
}

.data_columns_preview{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 20px;
    margin-top: 1em;
}

.data_columns_preview div{
    margin-bottom: 1em;
}

.data_columns_preview_select{
  
    padding: 0.5em;
    border-radius: 8px;
    height: 44px;
    border: 1px solid #DDD8D0;
    font-weight: 400;
    font-size: 14px;
    line-height: 22.4px;
    letter-spacing: -1.5%;
    color: #4B4F57;
    margin: 8px 8px 0px 0px;
    
}

.data_columns_preview_rows{

    font-weight: 400;
    font-size: 14px;
    line-height: 22.4px;
    letter-spacing: -1.5%;
    color: #4B4F57;
    padding: 12px;

}


.import-csv-previev-cont{
    margin-top: 30px;
    overflow-x: auto;
    width: auto;
}

.same-width {
    min-width: 150px; 
    word-wrap: break-word; 
}

.data_prev_btn{
    display: flex;
    justify-content: space-between;
    // align-items: center;
    
}
.data_prev_btn > div{
    display: flex;
    gap: 8px;
    // align-items: center;
}

.data_prev_btn h4{

    color: #007EFF;
    font-weight: 500;
    font-size: 1rem;
    cursor: pointer;
    letter-spacing: 0%;
  

}

.pdf-backgd{
    background: #000000;
    padding: 5em 5em 0em;
}
.pdf-main-cont{
    background: #FFFFFF;
    border-radius: 20px 20px 0px 0px;
  
}
.data-modal-prev-header{
    padding: 1em 1.5em;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #DDD8D0;
}
.pdf-header-btn{
    display: flex;
    gap: 12px;
}
.pdf-header-close-btn{
   
    padding: .5em 1em;
    border-radius: 8px;
    border: 1px solid #DDD8D0;
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 27.4px;
    color: #4B4F57;
    height: 29px;
    cursor: pointer;
    
}
.preview-column-txt{
    color: #000000;
    font-weight: 500;
    font-size: 14px;
    line-height: 22.4px;
    letter-spacing: 0%;
    padding: 12px;

}