.data_create_opt{
    display: flex;
    gap: 24px;
    margin-bottom: 60px;
}

.data_create_one{
    display: flex;
    gap: 12px;
    align-items: flex-start;
}
.data_create_one h4{
  
    font-weight: 500;
    font-size: 16px;
    line-height: 26.4px;
    letter-spacing: 0%;
    color:#101928 ;
    
}
.data_create_one p{
  
    font-weight: 400;
    font-size: 14px;
    line-height: 22.4px;
    letter-spacing: -1.5%;
    color: #9CA0A5;
}