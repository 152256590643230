// Alert.scss


.alert-container {
  position: fixed;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1000;
  display: flex;
  justify-content: center;
  width: 100%;
}
.alert {
  display: flex;
  align-items: flex-start;
  padding: 16px;
  gap: 10px;
  border-radius: 12px;
  font-family: "Inter", sans-serif;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  max-width: 500px;
  position: relative;
  ;

  &-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #e8f0fe;
    color: #1a73e8;
    font-size: 16px;
    margin-right: 12px;
  }

  &-content {
    flex-grow: 1;

    strong {
      display: block;
      font-weight: 600;
      font-size: 1.125rem;
      letter-spacing: -1%;
      color: #020712;
    }

    p {
      margin: 4px 0 0;
      font-weight: 400;
      font-size: 1rem;
      letter-spacing: 0%;
      color: #000;

    }
  }

  &-close {
    background: none;
    border: none;
    font-size: 16px;
    color: #5f6368;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 30%;
    padding: 2px;
    border: 1px solid #DDD8D0;
    transition: background 0.2s ease-in-out;

    &:hover {
      background: rgba(95, 99, 104, 0.1);
    }
  }
}
