.create_data_instr h4{
    padding: 1em 1.5em;
    background-color: #F6F6F6;
    border-radius: 8px;
    font-weight: 500;
    font-size: 1rem;
    margin-top: 24px;
}

.create_data_instr p{
    
    font-weight: 400;
    font-size: 1rem;
    line-height: 26.4px;
    color: #4B4F57;
}
.create_data_instr > div{
    display: flex;
    justify-content: center;
    margin: 10px 0px;
}
.create_data_instr button{
     background-color: #101928;
     color: #FFFFFF;
     padding: 1em 1.5em;
     border-radius: 8px;
}
.data_columns_preview_input{
    width: 140px;
    padding: 10px;
    border-radius: 4px;
    border: 1px solid #D9D9D9;
}
.data_create_preview_rows{

    font-weight: 400;
    font-size: 14px;
    line-height: 22.4px;
    letter-spacing: -1.5%;
    color: #4B4F57;
    padding: 12px 0px;
    
}
.data_create_preview_select{
    // width: 90%;
    padding: 0.5em;
    border-radius: 8px;
    height: 44px;
    border: 1px solid #DDD8D0;
    font-weight: 400;
    font-size: 14px;
    line-height: 22.4px;
    letter-spacing: -1.5%;
    color: #4B4F57;
    margin: 8px 8px 0px 0px;
    
}

.import-csv-previev-cont{
    margin-top: 30px;
    overflow-x: auto;
    width: auto;
}
.create-clm-name{
    
    font-weight: 500;
    font-size: 14px;
    line-height: 22.4px;
    letter-spacing: 0%;
    color: #101928;
    margin-bottom: 4px;
}

.create-clm-name input{
    width: 95%;
    height: 30px;
    border-radius: 6px;
    padding:.5em .75rem;
    gap: 12px;
    border:1px solid #DDD8D0;

}
.create-clm-name p{
    
    font-weight: 400;
    font-size: 14px;
    line-height: 22.4px;
    letter-spacing: -1.5%;
    color: #9CA0A5;
    margin: 8px 0px 20px;

}
.create-clm-name-select{
    width: 100%;
    height: 44px;
    border-radius: 6px;
    padding:.5em .75rem;
    gap: 12px;
    border:1px solid #DDD8D0;
    color: #000000;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 22.4px;
    letter-spacing: 1.5%;
    margin-bottom: 20px;

}
.create-clm-hdr{
    text-align: center;
    font-weight: 600;
    font-size: 28px;
    line-height: 36.4px;
    letter-spacing: 0%;
    margin-bottom: 32px;

}
.create-clm-desc{
    
    font-weight: 500;
    font-size: 1rem;
    line-height: 26.4px;
    letter-spacing: 0%;
    color: #101928;
    margin-bottom: 24px;

}
.create-clm-btn{
    width:100%;
    height: 56;
    border-radius: 12px;
    padding:1em .5em;
    gap: 10px;
    background: #101928;
    color: #FFFFFF;
    font-weight: 500;
    font-size: 1rem;
    line-height: 26.4px;
    letter-spacing: 0%;
    text-align: center;


}